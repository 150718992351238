import axios from 'axios';
import * as _ from 'lodash';
import storageService from './../service/storageService';
import { setUserData, addAccessToken } from './../reducer/state/action';
import { storageKey } from './../common/constant/constant.all';
import Enums from '../common/Enums';
import store from '../reducer/store'

const { getState, dispatch } = store;
// -------------------------------------------------------------- API Service Calls
//#region 
async function post(url, data = null) {
    const config = {
        url,
        method: 'post'
    };
    if (data) {
        config.data = data;
    }
    const result = await axios(config);
    return result;
}

async function postWithDownload(url, data = null) {
    const config = {
        url,
        method: 'post',
        responseType: 'blob'
    };
    if (data) {
        config.data = data;
    }
    const result = await axios(config);
    return result;
}

async function get(url, isDownloadRequest = false) {
    const config = {
        url,
        method: 'get'
    };

    if (isDownloadRequest) {
        config.responseType = 'blob';
    }

    const result = await axios(config);
    return result;
}

async function put(url, data = null) {
    const config = {
        url,
        method: 'put',
        data
    };
    const result = await axios(config);
    return result;
}

async function deleteCall(url) {
    const config = {
        url,
        method: 'delete'
    };
    const result = await axios(config);
    return result;
}
//#endregion

// -------------------------------------------------------------- Default API Configurations
//#region 
// Defaults
axios.defaults.baseURL = 'https://smartseizing.online/v2/api/';
// axios.defaults.baseURL = 'http://localhost:5000/v2/api/';
axios.defaults.withCredentials = true;

// Request interceptor
axios.interceptors.request.use(async (config) => {
    const state = getState();
    const { accessToken } = state.stateReducer;
    if (accessToken) {
        config.headers['authorization'] = 'Bearer ' + accessToken;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

async function refreshTokens() {
    return false;
}

// Response interceptor
axios.interceptors.response.use(async (response) => {
    if (response.data instanceof Blob) {
        const { data } = response;
        return { data }
    }

    const { status, data, friendlyErrorMessage, errorMessage } = await response.data;

    if (_.isNil(data) && status > Enums.statusCode.ok) {
        if (_.isEqual(status, Enums.statusCode.validationError)) {
            const error = {};
            Object.keys(errorMessage).map((key) => {
                error[key] = errorMessage[key][0];
            })
            return { data: null, error, isValidationError: true };
        } else if (_.isEqual(status, Enums.statusCode.unAuth)) {
            storageService.removeJsonItem(storageKey.userDetail);
            dispatch(setUserData(null));
            dispatch(addAccessToken(null));
        } else {
            return { data: null, error: friendlyErrorMessage, isValidationError: false };
        }
    }
    return { data, error: null, isValidationError: false, friendlyErrorMessage };
}, async (error) => {
    console.log(error);
    return { data: null, error: 'Oops something went wrong !', isValidationError: false };
});

//#endregion
const ApiService = {
    post,
    get,
    put,
    deleteCall,
    postWithDownload
};

export default ApiService;

// -------------------------------------------------------------- API URLs
//#region 
export const ApiUrls = {
    // Auth
    login: 'auth/web-login',

    // Admin
    adminLogin: 'admin/adminLogin',
    loginWithGuid: 'admin/loginWithGuid/{0}',

    // agency
    setUpiId: 'agency/set-upi-id',
    appWorkOn: 'agency/app/work-on',
    screenshotSetting: 'agency/app/ss-setting',
    appTiming: 'agency/app/timing',
    undoAppTiming: 'agency/app/undo/timing/{0}',

    // Vehicle
    getTotalRecords: 'vehicle/getTotalRecords',
    searchVehicleByMode: 'vehicle/searchVehicleByAggregate?search={0}&mode={1}&offset={2}',
    searchVehicleFromOwnDB: 'vehicle/search/from-own-vehicle?search={0}&mode={1}&offset={2}',
    searchVehicleFromOwnDBShared: 'vehicle/search/from-own-vehicle/shared?search={0}&mode={1}&offset={2}',
    uploadPsoFile: 'vehicle/uploadPsoFile',
    createPsoFile: 'vehicle/createPsoFile',
    createPsoPDF: 'vehicle/pso-file-pdf',
    getPsoFile: 'vehicle/psoFiles',
    deletePsoFile: 'vehicle/deletePsoFile/{0}',
    getVehicleDetailBillFiles: 'vehicle/vehicleDetailBills',
    getVehicleDetailBill: 'vehicle/vehicleDetailBill/{0}',
    deleteVehicleNumber: 'vehicle/deleteVehicleNumber/{0}',
    recoverVehicleNumber: 'vehicle/recoverVehicleNumber/{0}',
    vehiclePreferenceDetails: 'vehicle/vehiclePreferenceDetails',
    getPsoFormat: 'vehicle/psoFormat/{0}',
    getVehicleById: 'vehicle/detail/{0}',
    getVehiclesByNumber: 'vehicle/number/{0}',
    getVehicleTwoWheelerCount: 'vehicle/two-wheeler-count',

    // Email
    verifyEmail: 'email/verifyEmail',
    updateAdminEmail: 'email/updateAdminEmail',
    isVerifiedEmail: 'email/isVerifiedEmail',
    getIntimationTextById: 'email/getIntimationTextById/{0}',
    getEmailFormat: 'email/emailFormats',
    createFormat: 'email/createFormat',
    updateEmailFormat: 'email/updateEmailFormat',
    deleteEmailFormat: 'email/deleteEmailFormat/{0}',
    getStoredEmail: 'email/getStoredEmail',
    sendEmailWithFormat: 'email/sendEmailWithFormat/{0}',

    // message format
    createMessageFormat: 'message/createMessageFormat',
    updateMessageFormat: 'message/updateMessageFormat',
    deleteMessageFormat: 'message/deleteMessageFormat/{0}',
    getMessageFormat: 'message/messageFormats',

    // Finance
    getFinanceList: 'finance/getFinanceList',
    createFinance: 'finance/createFinance',
    updateFinance: 'finance/updateFinance/{0}',
    createFinanceBatch: 'finance/createFinanceBatch',
    updateFinanceBatch: 'finance/updateFinanceBatch/{0}',
    removeFinanceBatch: 'finance/removeFinanceBatch/{0}',
    getFinanceBatchList: 'finance/finance-batch',
    deleteFinanceById: 'finance/remove',

    // UploadFileAndUploadData
    getUploadFiles: 'uploadData/getUploadFiles',
    deleteUploadFileById: 'uploadData/deleteUploadFileById/{0}',
    storeDataInContainerWithCoord: 'uploadData/storeDataInContainerWithCoord',
    uploadFileAndCleanContainer: 'uploadData/uploadFileAndCleanContainer',
    storeDataVehicle: 'uploadData/storeDataVehicle',
    deleteVehicleDataByFileName: 'uploadData/deleteVehicleDataByFileName',
    addEmptySuggestion: 'uploadData/addEmptySuggestion',
    getGridSuggestion: 'uploadData/grid-suggestions',
    updateGridSuggestions: 'uploadData/updateGridSuggestions',
    downloadVehicleList: 'uploadData/downloadVehicleList',
    uploadFileAndData: 'uploadData/uploadFileAndData',
    getRecentUploadedFiles: 'uploadData/recent-new-files',
    sendToAndroid: 'uploadData/send-to-android',
    uploadDataWithFile: 'uploadData/uploadDataWithFile',
    uploadDataInOwnDB: 'uploadData/upload-data-in-own-collection',
    getLastDownloadLog: 'uploadData/lastDownloadLog',
    importVehicles: 'uploadData/importVehicles',
    totalSharedRecord: 'uploadData/shared/total-record',

    // Seizer 
    getSeizers: 'seizer',
    restartSeizerAccount: 'seizer/restartSeizerAccount',
    createSeizer: 'seizer/createSeizer',
    deleteSeizerByPhone: 'seizer/deleteSeizer',
    updateSeizerStatus: 'seizer/updateSeizerStatus/{0}',
    updateOnlineStatus: 'seizer/updateOnlineStatus',
    updateSeizerProfile: 'seizer/updateSeizerProfile/{0}',
    updateSeizerValidate: 'seizer/updateSeizerValidate/{0}',
    getSeizerLocations: 'seizer/seizerLocations',

    // Live
    getHistory: 'live/history',
    getUserHistory: 'live/user/history',
    getUserVehicleLocationHistory: 'live/user/history/{0}',
    getVehicleLocationHistory: 'live/vehicleLocationHistory',
    getUserHistoriesByFilter: 'live/users/histories-stats',
    getHistoryStats: 'live/history/stats',
    userHistoryStatsByUser: 'live/user/history-stats',
    getUserHistoriesByUser: 'live/user/histories',

    // User
    getUsers: 'seizer/users',
    getUserOptions: 'seizer/user/options',
    getUserLocations: 'seizer/user/location',
    createUser: 'seizer/createUser',
    updateUser: 'seizer/updateUser',
    updateUserPreference: 'seizer/updateUserPreference',
    activeBlockUser: 'seizer/active-block-user',
    restartUserAccount: 'seizer/user/restart/{0}',
    getUserById: 'seizer/user/{0}',
    getUsersForPortal: 'seizer/users/portal',
    rejectUserRequest: 'seizer/user/reject',
    approveUserRequest: 'seizer/user/approve/{0}',
    extendUserValidate: 'seizer/user/extend-date/{0}',
    removeUser: 'seizer/user/remove-user/{0}/{1}',
    preferenceFinanceBatch: 'seizer/user/preferenceFinanceBatch',
    checkUserByPhone: 'seizer/user/phonenumber/{0}',
    resetUserPassword: 'seizer/user/resetUserPassword',
    needUpdate: 'seizer/user/need-update/{0}',
    removeUserFinanceBatch: 'seizer/user/removeUserFinanceBatch/{0}/{1}',
    makeAdmin: 'seizer/user/makeAdmin/{0}',
    removeAdmin: 'seizer/user/removeAdmin/{0}',
    getUsersByBatchName: 'seizer/user/batch-users/{0}',
    updateUsersForBatch: 'seizer/user/update-batch',
    getWebUserById: 'seizer/user/web/{0}',

    // repossession
    getConfirmRequest: 'repossession/requests',
    deleteRepoById: 'repossession/delete/{0}',
    confirmRequest: 'repossession/confirm-request',
    getRepossessions: 'repossession',
    cancelRepoVehicle: 'repossession/cancel-repo-vehicle',

    // connection
    connectionAgencies: 'connection/agencies',
    connection: 'connection',
    connectionById: 'connection/detail/{0}',
    deleteConnectionById: 'connection/{0}',
    connectionByFilter: 'connection/filter',
    connectionStats: 'connection/stats',
};
//#endregion
